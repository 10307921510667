import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Login from "pages/Login";
import Register from "pages/Register";
import Header from "components/Header";
import AuthRoute from "components/AuthRoute";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import RenderRouteIf from "components/RenderRouteIf";
import PageWrapper from "components/PageWrapper";
import SessionForm from "pages/SessionForm";
import RegistrationRequestSent from "pages/RegistrationRequestSent";
import TeamJoinRequestSent from "pages/TeamJoinRequestSent";
import ApproveTeamJoinRequest from "pages/ApproveTeamJoinRequest";
import RecoverPasswordRequest from "pages/passwordRecovery/RecoveryPasswordRequest";
import RecoverPasswordRequestSent from "pages/passwordRecovery/RecoveryPasswordRequestSent";
import RecoverPasswordRequestFailed from "pages/passwordRecovery/RecoverPasswordRequestFailed";
import RecoverPassword from "pages/passwordRecovery/RecoverPassword";
import Admin from "pages/Admin";
import AdminContactForm from "pages/Admin/AdminContactForm";
import AdvancedWithToolCutInputDashboard from "pages/AdvancedWithToolCutInputDashboard/OldDashboard/index";
import DigiBasic from "pages/DigiBasic";
import DigiStability from "pages/DigiStability";
import FRFDatabase from "pages/PredictiveMaintenance/Pages/FRFDatabase/FRFDatabase.js";

import "react-widgets/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-widgets/styles.css";
import { ToastContainer } from "react-toastify";
import AdamosLoginSuccess from "pages/AdamosLoginSuccess";
import PredictiveMaintenance from "pages/PredictiveMaintenance";
import UserProfile from "pages/UserProfile";
// import Subscription from "pages/Subscription";
import EmailVerificationCompleted from "pages/EmailVerificationCompleted";
import EmailVerification from "pages/EmailVerification/EmailVerification";
import TeamManagement from "pages/TeamManagement";
import SpindleHealthMonitoring from "pages/PredictiveMaintenance/Pages/SpindleHealthMonitoring";
import MachineConfigurations from "pages/PredictiveMaintenance/Pages/MachineConfigurations";
import Dashboard from "pages/Dashboard";
import { SubscriptionProvider } from "./context/subscriptionContext";
import ReactGA from "react-ga4";
import SubscriptionCheck from "./context/SubscriptionCheck";

function App() {
  const { REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID } = process.env;

  useEffect(() => {
    if (REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
      ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
    }
  }, [REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID]);

  const [enlarged, setEnlarged] = useState(false);
  return (
    <Router>
      <ToastContainer />
      <div id="wrapper" className={enlarged ? "enlarged" : ""}>
        <RenderRouteIf
          notOnRoutes={[
            "/login",
            "/register",
            "/recover-password-request-failed",
            "/recover-password-request-sent",
            "/recovery-request",
            "/recover-password/:shortcode",
            "/registration-request-sent",
            "/team-join-request-sent",
            "/approve-team-join-request",
            "/email-verification",
            "/complete-registration/:registrationShortcode",
            "/complete-registration/:registrationShortcode/:invitedOrg/:shortcode/:invitationId",
            "/register-siemens",
          ]}
        >
          <Sidebar enlarged={enlarged} setEnlarged={setEnlarged} />
        </RenderRouteIf>
        <PageWrapper>
          <div className="content">
            <RenderRouteIf
              notOnRoutes={[
                "/login",
                "/register",
                "/recover-password-request-failed",
                "/recover-password-request-sent",
                "/recovery-request",
                "/recover-password/:shortcode",
                "/registration-request-sent",
                "/team-join-request-sent",
                "/approve-team-join-request",
                "/email-verification",
                "/complete-registration/:registrationShortcode",
                "/complete-registration/:registrationShortcode/:invitedOrg/:shortcode/:invitationId",
                "/register-siemens",
              ]}
            >
              <Header enlarged={enlarged} setEnlarged={setEnlarged} />
            </RenderRouteIf>
            <Switch>
              <Route path="/recover-password/:shortcode">
                <RecoverPassword />
              </Route>
              <Route path="/recover-password-request-failed">
                <RecoverPasswordRequestFailed />
              </Route>
              <Route path="/recover-password-request-sent">
                <RecoverPasswordRequestSent />
              </Route>
              <Route path="/recovery-request">
                <RecoverPasswordRequest />
              </Route>
              <Route path="/registration-request-sent">
                <RegistrationRequestSent />
              </Route>
              <Route path="/team-join-request-sent">
                <TeamJoinRequestSent />
              </Route>
              <Route path="/approve-team-join-request/:shortcode">
                <ApproveTeamJoinRequest />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/register-siemens">
                <Register />
              </Route>
              <Route path="/email-verification">
                <EmailVerification />
              </Route>
              <Route path="/complete-registration/:registrationShortcode/:invitedOrg/:shortcode/:invitationId">
                <EmailVerificationCompleted />
              </Route>
              <Route path="/adamos-success">
                <AdamosLoginSuccess />
              </Route>
              <AuthRoute path="/session-input">
                <SessionForm />
              </AuthRoute>
              <AuthRoute path="/admin/messages">
                <AdminContactForm />
              </AuthRoute>
              <AuthRoute path="/admin">
                <Admin />
              </AuthRoute>
              <AuthRoute path="/team-management">
                <TeamManagement />
              </AuthRoute>
              <AuthRoute path="/user-edit">
                <UserProfile />
              </AuthRoute>
              <SubscriptionProvider>
                <AuthRoute path="/digi-basic">
                  <SubscriptionCheck
                    subscriptionNames={[
                      "FEEDRATE OPTIMIZER",
                      "ESSENTIAL OPTIMIZER",
                      "FEEDS AND FEEDRATE OPTIMIZER",
                      "FEEDS AND ESSENTIAL OPTIMIZER",
                      "FINESSE AND FEEDRATE OPTIMIZER",
                      "FINESSE AND ESSENTIAL OPTIMIZER",
                    ]}
                  >
                    <DigiBasic />
                  </SubscriptionCheck>
                </AuthRoute>
                <AuthRoute path="/digi-stability">
                  <SubscriptionCheck
                    subscriptionNames={[
                      "ESSENTIAL OPTIMIZER",
                      "FEEDS AND ESSENTIAL OPTIMIZER",
                      "FINESSE AND ESSENTIAL OPTIMIZER",
                    ]}
                  >
                    <DigiStability />
                  </SubscriptionCheck>
                </AuthRoute>
                <AuthRoute path="/frf-database/:machineId">
                  <SubscriptionCheck
                    subscriptionNames={[
                      "ESSENTIAL OPTIMIZER",
                      "FEEDS AND ESSENTIAL OPTIMIZER",
                      "FINESSE AND ESSENTIAL OPTIMIZER",
                    ]}
                  >
                    <FRFDatabase />
                  </SubscriptionCheck>
                </AuthRoute>
                <AuthRoute path="/machine-configurations/:machineId">
                  <SubscriptionCheck
                    subscriptionNames={[
                      "ESSENTIAL OPTIMIZER",
                      "FEEDS AND ESSENTIAL OPTIMIZER",
                      "FINESSE AND ESSENTIAL OPTIMIZER",
                    ]}
                  >
                    <MachineConfigurations />
                  </SubscriptionCheck>
                </AuthRoute>
                <AuthRoute path="/advanced-with-tool-info">
                  <SubscriptionCheck
                    subscriptionNames={[
                      "ESSENTIAL OPTIMIZER",
                      "FEEDS AND ESSENTIAL OPTIMIZER",
                      "FINESSE AND ESSENTIAL OPTIMIZER",
                    ]}
                  >
                    <AdvancedWithToolCutInputDashboard />
                  </SubscriptionCheck>
                </AuthRoute>
                {/* <AuthRoute path="/subscription">
                  <Subscription enlarged={enlarged} />
                </AuthRoute> */}
                <AuthRoute path="/spindle-health-monitoring/:machineId">
                  <SubscriptionCheck
                    subscriptionNames={[
                      "ESSENTIAL OPTIMIZER",
                      "FEEDS AND ESSENTIAL OPTIMIZER",
                      "FINESSE AND ESSENTIAL OPTIMIZER",
                    ]}
                  >
                    <SpindleHealthMonitoring />
                  </SubscriptionCheck>
                </AuthRoute>
                <AuthRoute path="/predictive-maintenance">
                  <SubscriptionCheck
                    subscriptionNames={[
                      "ESSENTIAL OPTIMIZER",
                      "FEEDS AND ESSENTIAL OPTIMIZER",
                      "FINESSE AND ESSENTIAL OPTIMIZER",
                    ]}
                  >
                    <PredictiveMaintenance />
                  </SubscriptionCheck>
                </AuthRoute>
                <AuthRoute exact path="/">
                  <Dashboard enlarged={enlarged} />
                </AuthRoute>
              </SubscriptionProvider>
            </Switch>
          </div>
        </PageWrapper>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
