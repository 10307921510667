import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import * as yup from "yup";
import {
  Form,
  InputNonOptimized,
  ErrorMessage,
  SubmitButton,
} from "components/Forms";
import Authentication from "services/Authentication";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "./style.css";

let auth = new Authentication();

const getQueryParams = (query = null) =>
  (query || window.location.search.replace("?", ""))
    .split("&")
    .map((e) => e.split("=").map(decodeURIComponent))
    // eslint-disable-next-line no-sequences
    .reduce((r, [k, v]) => ((r[k] = v), r), {});

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
    .label("Email")
    .typeError("Email must be a valid email address."),
  password: yup
    .string()
    .label("Password")
    .required()
    .typeError("Please fill in the password field"),
});
const Login = () => {
  let history = useHistory();
  const [invalidCredentialsError, setInvalidCredentialsError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAdamosNativeLoginInProgress, setAdamosNativeLoginInProgress] =
    useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const captchaRef = useRef(null);
  const { REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY } = process.env;
  const setIsVerified = false;

  const handleLogin = async (formValues) => {
    try {
      const captcha = await captchaRef.current.getValue();
      const { email, password } = formValues;
      if (email && password) {
        setLoading(true);
        await auth.login({ email, password, captcha });
        setLoading(false);
        history.push("/");
      }
    } catch (err) {
      setLoading(false);
      captchaRef.current.reset();
      if (err.response && err.response.status === 451) {
        setInvalidCredentialsError("User is already logged in.");
        setLoading(false);
      } else if (err.response.status === 500 || err.response.status === 400) {
        setInvalidCredentialsError("Captcha verification failed");
        setLoading(false);
      } else {
        setInvalidCredentialsError("Incorrect Email or Password");
        setLoading(false);
      }
    }
  };

  const handleAdamosLogin = (event) => {
    event?.preventDefault();
    const adamosAuthURL = `${process.env.REACT_APP_ADAMOS_OAUTH_URL}/authorize?response_type=code&client_id=${process.env.REACT_APP_ADAMOS_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ADAMOS_REDIRECT_URL}&scope=openid%20profile%20email`;
    window.location.replace(adamosAuthURL);
  };

  useEffect(() => {
    const { iss } = getQueryParams();
    if (iss && iss.includes(process.env.REACT_APP_ADAMOS_OAUTH_URL)) {
      setAdamosNativeLoginInProgress(true);
      handleAdamosLogin();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleShowPassword = (event) => {
    event.preventDefault();
    setPasswordShown(!passwordShown);
  };
  const handleVerify = () => {
    setIsVerified(true);
  };
  return (
    <>
      <a href="https://productivemachines.co.uk/">
        <img
          className="login-logo m-2 ml-4"
          src="assets/images/ll4.png"
          alt="logo"
        />
      </a>
      <div className="account-pages m-0" style={{ backgroundColor: "white" }}>
        {!isAdamosNativeLoginInProgress && (
          <div className="row">
            <div className="col d-flex align-items-center justify-content-center">
              <img
                className="login-image"
                src="assets/images/SignOnPage.png"
                alt="logo"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <div>
                <div className="card-body">
                  <div className="p-1">
                    <h1 className="text-center">Chatter-free Milling</h1>
                    <h2 className="text-center mt-4">Welcome Back</h2>
                  </div>
                  <div className="p-1">
                    <Form
                      className="form-horizontal"
                      initialValues={{}}
                      validationSchema={schema}
                      onSubmit={handleLogin}
                    >
                      <div className="form-group row">
                        <div className="col-12 mt-4 d-flex justify-content-center align-items-center">
                          {" "}
                          <InputNonOptimized
                            name="email"
                            className="form-control login-input"
                            type="email"
                            placeholder="Email Address"
                          />
                        </div>
                        <ErrorMessage name="email" />
                      </div>
                      <div className="form-group row">
                        <div className=" col-12 mt-3">
                          <div className="d-flex justify-content-center align-items-center">
                            <InputNonOptimized
                              name="password"
                              className="form-control login-password-input"
                              type={passwordShown ? "text" : "password"}
                              placeholder="Password"
                            />
                            <button className="show-password-button">
                              <i
                                onClick={(event) => handleShowPassword(event)}
                                className={
                                  passwordShown
                                    ? "ion ion-md-eye"
                                    : "ion ion-md-eye-off"
                                }
                              />
                            </button>
                          </div>
                        </div>
                        <ErrorMessage name="password" />
                        {invalidCredentialsError && (
                          <p className="text-danger mb-0">
                            {invalidCredentialsError}
                          </p>
                        )}
                      </div>
                      <div className="form-group row m-t-18">
                        <Link
                          to="/recovery-request"
                          className="text-muted forget-password-button"
                          style={{ marginLeft: "12rem" }}
                        >
                          Forgot Password?{" "}
                        </Link>
                        <div className="col-12 mt-3 d-flex justify-content-center">
                          <SubmitButton
                            className="btn btn-primary btn-lg login-button"
                            type="submit"
                            onClick={handleLogin}
                          >
                            {loading ? (
                              <i className="fas fa-circle-notch fa-spin"></i>
                            ) : (
                              "Sign In"
                            )}
                          </SubmitButton>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                          <ReCAPTCHA
                            sitekey={REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                            onChange={handleVerify}
                            ref={captchaRef}
                          />
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div
                    className="card-body"
                    style={{
                      marginBottom: "3rem",
                      width: "10rem",
                      marginTop: "-3rem",
                    }}
                  >
                    {/* <div className="p-1">
                      <h4 className="text-center mt-4">
                        Don't have an account?
                      </h4>
                    </div>
                    <div className="p-1">
                      <div className="form-group row">
                        <h5 className="text-muted text-center mt-4 login-name">
                          Get started for free today. Try our Essential
                          Optimization Tools and see what's coming soon.
                        </h5>
                      </div>
                      <div className="form-group text-center row m-t-20">
                        <div className="col-12">
                          <div className="d-flex justify-content-center">
                            <Link
                              className="btn btn-outline-primary waves-effect waves-light create-an-account-button"
                              to="/register"
                            >
                              Create an account
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
