import React from "react";

const EmailVerification = () => {
  return (
    <>
      <img className="login-logo" src="assets/images/ll2.png" alt="logo" />
      <div
        className="mt-5"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="col-lg-5 mt-5">
          <div className="card mb-0 mt-5">
            <div className="card-body">
              <div className="mb-4 text-center">
                <h3 className="mb-0" style={{ fontFamily: "Poppins" }}>
                  Email Verification
                </h3>
                <p
                  className="my-2 mt-4"
                  style={{ fontFamily: "Montserrat", fontSize: "16px" }}
                >
                  Email is required. Please check your inbox to proceed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerification;
