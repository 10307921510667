import { XestTable } from "components/CustomComponents";
import React from "react";
import getUsers from "services/getUsers";

const UsersTable = () => {
  const columns = [
    {
      dataIndex: "first_name",
      title: "First Name",
      filterType: {
        dbCol: "users.first_name",
        type: "string",
      },
      width: 125,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: "last_name",
      title: "Last Name",
      filterType: {
        dbCol: "users.last_name",
        type: "string",
      },
      width: 125,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: "email",
      title: "Email",
      filterType: {
        dbCol: "users.email",
        type: "string",
      },
      width: 120,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: "organization_id",
      title: "Organization Id",
      filterType: {
        dbCol: "personal_information.organization_id",
        type: "string",
      },
      width: 135,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: "created_at",
      title: "Created At",
      filterType: {
        dbCol: "personal_information.created_at",
        type: "date",
      },
      width: 145,
      ellipsis: { showTitle: true },
    },
  ];
  const params = {
    apiCallFn: async (queryParams) => {
      let data, error;
      await getUsers({ queryParams })
        .then(async (res) => {
          data = res.data;
        })
        .catch((err) => (error = err));

      return {
        data,
        error,
      };
    },
    deps: [1],
    initialPageSize: 10,
    initialFilters: [],
    initialSortCriteria: null,
    config: {
      filtersKeyGen() {
        return "dt--users";
      },
      tableNum: 3,
    },
  };
  return <XestTable columns={columns} params={params} />;
};

export default UsersTable;
