/*
  Group endpoints by resource and HTTP Method
*/

const user = {
  login: () => `/login`,
  deleteLoginSession: () => `/delete-login-session`,
  register: () => `/register`,
  putPersonalDetails: () => `/profile-edit/personal`,
  deleteUserById: ({ userId }) => `/delete-user/${userId}`,
  requestPasswordRecovery: () => `/recovery-request`,
  updatePassword: ({ shortcode }) => `/update-password/${shortcode}`,
  patchUserDetails: () => `/profile-edit/personal`,
  patchUserPassword: () => `/user/password`,
  postNotifyMeDashboard: () => `/notify-me-dashboard`,
  getNotifyMe: () => `/notify-me`,
  deleteNotifyMeById: () => `/notify-me-by-id`,
  deleteNotifyMeDashboardById: () => `/notify-me-dashboard-by-id`,
  getNotifyMeDashboard: () => `/notify-me-dashboard`,
};

const users = {
  putUserOrganization: ({ userId }) =>
    `/remove-user-from-organization/${userId}`,
  getUsers: ({ queryParams }) => `/users?${queryParams}`,
  getNotifymeUsers: ({ queryParams }) => `/notifymeUsers?${queryParams}`,
  getNotifymeUsersLandingPage: ({ queryParams }) =>
    `/notifymeUsersLandingPage?${queryParams}`,
  getRegistrationRequests: () => `/registration-requests`,
  postRegistrationRequest: ({
    invitedOrganizationId,
    shortCode,
    invitationId,
  }) =>
    `/registration-request/${invitedOrganizationId}/${shortCode}/${invitationId}`,
  patchApproveRegistrationRequestById: ({ registrationRequestId }) =>
    `/approve-registration-request/${registrationRequestId}`,

  patchRejectRegistrationRequestById: ({ registrationRequestId }) =>
    `/reject-registration-request/${registrationRequestId}`,
  postCompleteRegistration: ({
    registrationShortcode,
    invitedOrg,
    shortcode,
    invitationId,
  }) =>
    `/complete-registration/${registrationShortcode}/${invitedOrg}/${shortcode}/${invitationId}`,
};

const landingPage = {
  getContactFormMessages: () => `/contact-messages`,
  getBookingRequests: () => `/demo-requests`,
};

const materials = {
  postMaterials: () => `/materials`,
};

const organizations = {
  getOrganizations: () => `/organizations`,
  getOrganizationsByEmailProtocol: ({ email }) =>
    `/org-email-protocol?email=${email}`,
  postNewOrganization: () => `/new-organization`,
  postOrganizationJoinRequest: () => `/organization-join-request`,
  patchApproveJoinTeamRequestStatusByOrgId: ({ shortcode }) =>
    `/approve-join-team-request/${shortcode}`,
  getOrganizationIdentifierByOrganizationId: () => `/org-identifier-by-id`,
  getOrganizationMembersByOrganizationId: () => `/org-members-by-id`,
  getOrganizationAdminById: () => `/org-admin-by-id`,
  postOrganizationInvitation: () => `/organization/invitation`,
  getUserInvitations: () => `/user/invitations`,
  deleteInvitation: ({ invitationId }) => `/user/invitations/${invitationId}`,
  patchUserInvitation: () => `/user/invitation-details`,
};

const sessions = {
  getAllSessions: () => `/sessions`,
  getUserSessions: () => `/user-sessions`,
  getSessionById: ({ sessionId }) => `/session/${sessionId}`,
  deleteSessionById: ({ sessionId }) => `/delete/session/${sessionId}`,
  postUserSession: () => `/user-session`,
  putProgressStatusId: ({ sessionId }) => `/update/session-status/${sessionId}`,
  postPrtUpload: () => `/prt-upload`,
  deletePrtUploadById: ({ uploadId }) => `/delete/upload/${uploadId}`,
  getPrtUploadsByUploadIds: ({ uploadIds }) =>
    `/list-prt-uploads?prtUploadIds=${uploadIds}`,
};

const cuttingTools = {
  getSessionCuttingToolByIds: ({ cuttingToolIds }) =>
    `/list-session-cutting-tools?cuttingToolIds=${cuttingToolIds}`,
  postSessionCuttingTool: () => `/session-cutting-tool`,
  deleteSessionCuttingToolById: ({ toolId }) =>
    `/delete/session-cutting-tool/${toolId}`,
};

const files = {
  downloadFile: ({ ownerId, fileName }) =>
    `/download-file/${ownerId}/${fileName}`,
  uploadFile: () => `/upload-file`,
  getFileAsJson: ({ ownerId, fileName }) =>
    `/get-file-as-json/${ownerId}/${fileName}`,
};

const digiBasic = {
  getDigibasicToolDataByOrgId: () => `/digibasic-tools`,
  getDigibasicToolTypes: () => `/digibasic-tool-types`,
  postDigiBasicToolData: () => `/digibasic-tool-data`,
  postDigiBasicFeedRateOptimisation: () => `/digibasic-feed-rate-optimisation`,
  getDigibasicFeedRate: () => `/digibasic-feed-rate`,
  getDigibasicConstraints: () => `/digibasic-constraints`,
  getTotalOfDigibasicToolDataByOrgId: () => `/total-of-digibasic-tools`,
  getDigibasicTools: ({ queryParams }) => `/digibasicTools?${queryParams}`,
};

const digiStability = {
  getDigiStabilityToolData: () => `/digi-stability-tools`,
  getDigiStabilityToolTypes: () => `/digi-stability-tool-types`,
  getDigiStabilityToolDataByOrgId: () => `/digi-stability-tools`,
  postDigiStabilityToolData: () => `/digi-stability-tool-data`,
  postDigiStabilityFeedRateOptimisation: () =>
    `/digi-stability-feed-rate-optimisation`,
  getDigiStabilityFeedRate: () => `/digi-stability-feed-rate`,
  getDigiStabilityConstraints: () => `/digi-stability-constraints`,
  getDigiStabilityMatchingFrfDatumIds: () =>
    `/get-matching-digi-stability-machine-configuration-id/:machineId/:stickOutLength/:toolHolderId/:spindleId/:cuttingToolId`,
  getDigiStabilityOperationBatchByUserId: () =>
    `/digi-stability-operation-batch-by-user-id`,
  getDigiStabilityMaterials: () => `/get-digi-stability-materials`,
};

const payments = {
  getPaymentsById: () => `/fetch-payment-by-id`,
};

const subscriptions = {
  getSubscriptionByUserId: () => `/subscription`,
};

const predictiveMaintenance = {
  getMachineTools: () => `/machine-tools`,
  getMachines: () => `/machines`,
  getMachineByMachineId: ({ machineId }) => `/machine-by-id/${machineId}`,
  postMachine: () => `/post-machine`,
  putMachines: (machineId) => `/machines/${machineId}`,
  getMachineToolTypes: () => `/machine-tool-types`,
  deleteMachineById: ({ machineId }) => `/delete-machine/${machineId}`,
  getMachineExtensionsByMachineId: ({ machineId }) =>
    `/machine-extensions-by-machine-id/${machineId}`,
  postMachineExtensions: () => `/machine-extensions`,
};

const frfDatabase = {
  postFrfData: () => `/frf-data`,
  postMaintenanceFrfData: () => `/maintenance-frf-data`,
  deleteMaintenanceFrfDataById: () => `/maintenance-frf-data`,
  getFrfDataByMachineId: ({ machineId }) =>
    `/frf-data-by-machine-id/${machineId}`,
  getMaintenanceFrfDataByMachineId: ({ machineId }) =>
    `/maintenance-frf-data-by-machine-id/${machineId}`,
  getEventLogsByMachineId: ({ machineId }) => `/event-logs/${machineId}`,
  postEventLogByMachineId: () => `/event-log-by-machine-id`,
  putEventLog: () => `/put-event-log`,
};

const shopFloor = {
  postOrganizationShopFloorData: ({ organizationId }) =>
    `/organization/${organizationId}/shop-floor-data`,

  putOrganizationShopFloorData: ({ organizationId }) =>
    `/organization/${organizationId}/shop-floor-data`,

  getOrganizationShopFloorData: ({ organizationId }) =>
    `/organization/${organizationId}/shop-floor-data`,
};

export {
  user,
  users,
  materials,
  cuttingTools,
  organizations,
  sessions,
  files,
  landingPage,
  digiBasic,
  digiStability,
  payments,
  predictiveMaintenance,
  frfDatabase,
  subscriptions,
  shopFloor,
};
