import http from "utils/http";
import { user } from "constants/endpoints";

const requestRecoverPassword = ({ requestedEmail }) => {
  const ENDPOINT = user.requestPasswordRecovery();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      requestedEmail,
    },
  });
};

export default requestRecoverPassword;
