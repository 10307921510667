import React, { useState } from "react";
import { useHistory } from "react-router";
import useForm from "effects/useForm";
import requestRecoverPassword from "services/passwordRecovery/requestRecoverPassword";

const RecoverPasswordRequest = () => {
  let history = useHistory();
  const [invalidCredentialsError, setInvalidCredentialsError] = useState("");
  const [loading, setLoading] = useState(false);

  const { email, emailErrorMessage, setEmail, isValid } = useForm({
    email: {
      validation: (email) => {
        if (email === "") {
          return "Email is required";
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
          return "Please enter a valid email address";
        }
      },
      initialValue: "",
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await requestRecoverPassword({ requestedEmail: email });
      setLoading(false);
      history.push("/recover-password-request-sent");
    } catch (err) {
      setLoading(false);
      setInvalidCredentialsError(
        "There is no registered user with this email."
      );
    }
  };

  return (
    <>
      <img className="login-logo" src="assets/images/ll2.png" alt="logo" />
      <div
        className="mt-5"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="col-lg-5 mt-5">
          <div className="card mb-0 mt-5">
            <div className="card-body">
              <div className="mb-4 text-center">
                <h3 className="mb-0" style={{ fontFamily: "Poppins" }}>
                  Reset Password
                </h3>
                <p
                  className="my-2 mt-4"
                  style={{ fontFamily: "Montserrat", fontSize: "16px" }}
                >
                  Enter your email and instructions will be sent to you!
                </p>
              </div>
              <form>
                <div className="uk-form-group">
                  <label className="uk-form-label" style={{ fontSize: "14px" }}>
                    Email
                  </label>

                  <div className="uk-position-relative w-100">
                    <span className="uk-form-icon">
                      <i className="icon-feather-mail"></i>
                    </span>

                    <input
                      className="form-control"
                      type="email"
                      placeholder="name@example.com"
                      style={{ fontFamily: "Montserrat" }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                    />
                  </div>
                </div>
                {emailErrorMessage && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      "margin-top": "10px",
                    }}
                  >
                    {emailErrorMessage}
                  </p>
                )}
                {invalidCredentialsError && (
                  <div className="login-error-wrapper">
                    <p className="text-danger">{invalidCredentialsError}</p>
                  </div>
                )}
                <div
                  className="mt-4 uk-flex-middle uk-grid-small"
                  data-uk-grid
                  style={{ justifyContent: "center" }}
                >
                  <div className="uk-width-auto@s">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg w-100"
                        disabled={!isValid || loading}
                        onClick={handleSubmit}
                      >
                        {loading ? "Sending Email.." : "Send Recovery Email"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoverPasswordRequest;
