import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Authentication from "services/Authentication";
// import getOrganizationAdminById from "services/getOrganizationAdminById";
// import useAPI from "effects/useAPI";

let auth = new Authentication();

const Sidebar = ({ setEnlarged, enlarged }) => {
  let history = useHistory();
  const jwtPayload = auth.getAccessTokenPayload();

  // const [
  //   errorOrganizationAdmin,
  //   // eslint-disable-next-line no-unused-vars
  //   loadingOrganizationAdmin,
  //   organizationAdminResponse,
  // ] = useAPI(() => getOrganizationAdminById());

  // const { organizationAdmin } = organizationAdminResponse;

  return (
    <div className="left side-menu">
      <button
        type="button"
        className="button-menu-mobile button-menu-mobile-topbar open-left waves-effect"
        onClick={() => setEnlarged(!enlarged)}
      >
        <i className="mdi mdi-close"></i>
      </button>

      <div className="left-side-logo d-block d-lg-none">
        <div className="text-center">
          <Link to="/" className="logo">
            <img src="assets/images/logos.png" height="20" alt="logo" />
          </Link>
        </div>
      </div>

      <div
        className="sidebar-inner slimscrollleft"
        style={{ position: "fixed" }}
      >
        <div id="sidebar-menu">
          <ul>
            <li className="menu-title">Main</li>

            <li>
              <Link to="/" className="waves-effect">
                <i className="dripicons-home"></i>
                <span> Dashboard </span>
              </Link>
            </li>

            {jwtPayload.userTypeId === Number(1) && (
              <li>
                <Link
                  to="/admin"
                  className="waves-effect"
                  onClick={() => history.push(`/admin`)}
                >
                  {/* history shall be fixed */}
                  <i className="dripicons-browser"></i>
                  <span>Admin Panel</span>
                  {/* <span className="badge badge-success badge-pill float-right">
                    3
                  </span> */}
                </Link>
              </li>
            )}
            {jwtPayload.userTypeId === Number(1) && (
              <li>
                <Link
                  to="/admin/messages"
                  className="waves-effect"
                  onClick={() => history.push(`/admin/messages`)}
                >
                  <i className="dripicons-browser"></i>
                  <span>Customer Messages</span>
                </Link>
              </li>
            )}
            <li>
              <Link
                to="/team-management"
                className="waves-effect"
                onClick={() => history.push(`/team-management`)}
              >
                <i className="dripicons-browser"></i>
                <span>Team Management</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
};

export default Sidebar;
