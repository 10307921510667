import http from "utils/http";
import { users } from "constants/endpoints";

const postCompleteRegistration = ({
  registrationShortcode,
  invitedOrg,
  shortcode,
  invitationId,
}) => {
  const ENDPOINT = users.postCompleteRegistration({
    registrationShortcode,
    invitedOrg,
    shortcode,
    invitationId,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {});
};

export default postCompleteRegistration;
